import React  from 'react';
import {NavLink,  withRouter}  from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import MetaTags from 'react-meta-tags';


import '../componentes.css'; 
import 'font-awesome/css/font-awesome.min.css';

import { Icon } from '@iconify/react';
 
 
class Blog extends React.Component {
	
	  componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	  
	

	render() {

 
		return(
	
			<>

      <MetaTags>
				<title>Blog | Garlaw Abogados</title>
				<meta name="description" content="La actualidad jurídica a tu alcance. Descubre las novedades en el mundo del derecho de forma fácil y sencilla." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Blog | Garlaw Abogados" />
				<meta property="og:description" content="La actualidad jurídica a tu alcance. Descubre las novedades en el mundo del derecho de forma fácil y sencilla." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="La actualidad jurídica a tu alcance. Descubre las novedades en el mundo del derecho de forma fácil y sencilla." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

	<section className="site-section pt-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-10">
          <h1>Garlaw Abogados <span>Blog</span></h1>
          <p className = "p-blog-2"> La actualidad jurídica a tu alcance. </p>
          </div>
        </div>
        <div className="row blog-entries">
          <div className="col-md-12 col-lg-8 main-content">
            <div className="row mb-5 mt-5">

              <div className="col-md-12">

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-actualizacion-renta-alquiler" title = "La actualización de la renta en los Contratos de Arrendamiento">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_actualizacion_renta.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Enero 21, 2025 </span>
                      </div>
                      <h2 className ="post-h2">La actualización de la renta en los Contratos de Arrendamiento</h2>
                    </span>
                  </NavLink>
                </div>  

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-ibi-pendiente-compraventa" title = "IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_ibi.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Diciembre 9, 2024 </span>
                      </div>
                      <h2 className ="post-h2">IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?</h2>
                    </span>
                  </NavLink>
                </div>  


              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-sentencia-extranjera-de-divorcio" title = "Sentencia Extranjera de Divorcio, ¿es válida en España?">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_sentencia_extranjera_divorcio.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Noviembre 10, 2024 </span>
                      </div>
                      <h2 className ="post-h2">Sentencia Extranjera de Divorcio, ¿es válida en España?</h2>
                    </span>
                  </NavLink>
                </div>  

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-cuestiones-compraventa" title = "Cuestiones a tener en cuenta en una compraventa">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_cuestiones_compraventa.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Octubre 8, 2024 </span>
                      </div>
                      <h2 className ="post-h2">Cuestiones a tener en cuenta en una compraventa</h2>
                    </span>
                  </NavLink>
                </div>  

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-dinero-privativo-en-cuenta-ganancial" title = "¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_dinero_privativo.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Septiembre 10, 2024 </span>
                      </div>
                      <h2 className ="post-h2">¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?</h2>
                    </span>
                  </NavLink>
                </div> 

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-padres-separados-colegio" title = "¿Debe informar el colegio a los padres separados?">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_colegio.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Julio 10, 2024 </span>
                      </div>
                      <h2 className ="post-h2">¿Debe informar el colegio a los padres separados?</h2>
                    </span>
                  </NavLink>
                </div> 

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-precario" title = "¿Pueden mis padres echar a mi expareja de su piso?">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_precario.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Mayo 22, 2024 </span>
                      </div>
                      <h2 className ="post-h2">¿Pueden mis padres echar a mi expareja de su piso?</h2>
                    </span>
                  </NavLink>
                </div> 

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-gastos-universidad-en-divorcio" title = "¿Quién paga los gastos universitarios de los hijos en común tras una separación o divorcio?">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_gastos_universidad.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Marzo 5, 2024 </span>
                      </div>
                      <h2 className ="post-h2">¿Quién paga los gastos universitarios de los hijos en común tras una separación o divorcio?</h2>
                    </span>
                  </NavLink>
                </div> 

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-cargo-tarjeta-no-autorizado" title = "¿Cargos en tu tarjeta que no has autorizado?">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_cargo_tarjeta.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Diciembre 12, 2023 </span>
                      </div>
                      <h2 className ="post-h2">¿Cargos en tu tarjeta que no has autorizado?</h2>
                    </span>
                  </NavLink>
                </div> 

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-divorcio-contacto-hijos" title = "¿Qué puedo hacer si me expareja no me deja hablar con mi hijo?">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_divorcio_hijos.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Noviembre 13, 2023 </span>
                      </div>
                      <h2 className ="post-h2">¿Qué puedo hacer si me expareja no me deja hablar con mi hijo?</h2>
                    </span>
                  </NavLink>
                </div> 

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-pension-alimentos-vacaciones" title = "Pensión de Alimentos en Vacaciones de Verano">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_pension_verano.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Octubre 10, 2023 </span>
                      </div>
                      <h2 className ="post-h2">¿Debo pagar la pensión de alimentos en las vacaciones de verano?</h2>
                    </span>
                  </NavLink>
                </div> 

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-nueva-ley-vivienda" title = "Nueva Ley de Vivienda">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_nueva_ley_vivienda.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Septiembre 12, 2023 </span>
                      </div>
                      <h2 className ="post-h2">Principales novedades de la Nueva Ley de Vivienda</h2>
                    </span>
                  </NavLink>
                </div> 

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-heredero-menor-de-edad" title = "Heredero Menor de Edad">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_heredero_menor.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Julio 3, 2023 </span>
                      </div>
                      <h2 className ="post-h2">¿Qué ocurre cuando los herederos son menores de edad?</h2>
                    </span>
                  </NavLink>
                </div> 

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-contratos-arras" title = "Contratos de Arras">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_contratos_arras.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Junio 12, 2023 </span>
                      </div>
                      <h2 className ="post-h2">Contrato de Arras: ¿qué es y qué debemos saber?</h2>
                    </span>
                  </NavLink>
                </div>
                
              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-claves-contratos-alquiler" title = "Contratos Alquiler">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_claves_alquiler.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Mayo 16, 2023 </span>
                      </div>
                      <h2 className ="post-h2">Todas las Claves de los Contratos de Alquiler </h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-vicios-constructivos" title = "Vicios Constructivos">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_vicios_constructivos.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Abril 18, 2023 </span>
                      </div>
                      <h2 className ="post-h2">Vicios Constructivos </h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-inteligencia-artificial" title = "La inteligencia artificial en el mundo del Derecho">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_inteligencia_artificial.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Marzo 21, 2023 </span>
                      </div>
                      <h2 className ="post-h2">La Inteligencia Artificial en el mundo del Derecho </h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-incumplimiento-regimen-visitas" title = "Incumplimiento del Régimen de Visitas">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_incumplimiento_visitas.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Febrero 27, 2023 </span>
                      </div>
                      <h2 className ="post-h2">El Incumplimiento del Régimen de Visitas </h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-reclamacion-factura" title = "Reclamación de una Factura">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_reclamacion_factura.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Enero 30, 2023 </span>
                      </div>
                      <h2 className ="post-h2">¿Tienes que reclamar una factura de luz o gas?</h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-vivienda-divorcio" title = "Vivienda Divorcio">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_vivienda_divorcio.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Enero 9, 2023 </span>
                      </div>
                      <h2 className ="post-h2">¿A quién le corresponde el uso de la vivienda familiar tras un divorcio?</h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-vivienda-heredada" title = "Vivienda Heredada">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_vivienda_heredada.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Diciembre 12, 2022 </span>
                      </div>
                      <h2 className ="post-h2">¿Qué pasa si uno de los hermanos vive en la vivienda heredada? ¿podemos echarle?</h2>
                    </span>
                  </NavLink>
                </div>


              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-tipos-de-divorcios" title = "Tipos de Divorcio en España">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_tipos_divorcio.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Noviembre 22, 2022 </span>
                      </div>
                      <h2 className ="post-h2">Tipos de Divorcio en España</h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-medidas-de-apoyo-a-personas-con-discapacidad" title = "Medidas de Apoyo a las Personas con Discapacidad">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_medidas_discapacidad.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Octubre 21, 2022 </span>
                      </div>
                      <h2 className ="post-h2">Medidas de Apoyo a las Personas con Discapacidad</h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-pension-alimentos" title = "Post Cuantía Pensión Alimentos">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_cuantia_pension.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Septiembre 26, 2022 </span>
                      </div>
                      <h2 className ="post-h2">Cuantía mínima de la pensión de alimentos a favor de los hijos</h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-divorcio-mascotas" title = "Post Divorcio Mascota">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_divorcio_mascota.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Septiembre 1, 2022 </span>
                      </div>
                      <h2 className ="post-h2">Divorcio o Separación y ahora... ¿quién se queda con la mascota?</h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-novedades-propiedad-horizontal" title = "Post Novedades en Propiedad Horizontal">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_novedades_propiedad_horizontal.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Julio 20, 2022 </span>
                      </div>
                      <h2 className ="post-h2">Novedades en la Ley de Propiedad Horizontal </h2>
                    </span>
                  </NavLink>
                </div>

              <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-novedades-arrendamientos" title = "Post Novedades en los Arrendamientos">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_arrendamientos.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Julio 7, 2022 </span>
                      </div>
                      <h2 className ="post-h2">Límite a la Subida de los Alquileres - Principales Novedades </h2>
                    </span>
                  </NavLink>
                </div>

                <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-propiedad-horizontal" title = "Post Alquiler Vacacional">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_phorizontal.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Julio 20, 2021 </span>
                      </div>
                      <h2 className ="post-h2">¿Pueden las Comunidades de Propietarios prohibir el alquiler vacacional?</h2>
                    </span>
                  </NavLink>
                </div>

                <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-patria-potestad" title = "Post Patria Potestad">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_conflicto.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Abril 20, 2021 </span>
                      </div>
                      <h2 className ="post-h2">¿Qué ocurre en caso de desacuerdo entre los padres sobre cuestiones importantes en la vida de su hijo menor?</h2>
                    </span>
                  </NavLink>
                </div>


                <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-testamento" title = "Post Testamento">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_testamento.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Enero 11, 2021 </span>
                      </div>
                      <h2 className ="post-h2">Testamento ¿Debo hacerlo?</h2>
                    </span>
                  </NavLink>
                </div>


                <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-smart-contract" title = "Post Smart Contract">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_smart.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Julio 3, 2020 </span>
                      </div>
                      <h2 className ="post-h2">Smart Contract</h2>
                    </span>
                  </NavLink>
                </div>


                <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-covid-arrendamientos" title = "Post Covid y Arrentamientos">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_covid.webp')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Abril 16, 2020 </span>
                      </div>
                      <h2 className ="post-h2">Informe Jurídico sobre el impacto del COVID-19 en los contratos de arrendamiento</h2>
                    </span>
                  </NavLink>
                </div>


                <div className="post-entry-horzontal">
                  <NavLink to="/blog/post-erte" title = "Post Guía sobre los ERTE">
                    <div className="image element-animate" data-aos="zoom-in" data-aos-delay="100" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/post_erte.png')"}}>
                    </div>
                    <span className="text">
                      <div className="post-meta">
                        <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                        <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                        <span className="mr-2">Abril 21, 2020 </span>
                      </div>
                      <h2 className ="post-h2">Guía sobre los ERTE</h2>
                    </span>
                  </NavLink>
                </div>



              </div>
            </div>
          </div>

 
          <div className="col-md-12 col-lg-4 sidebar">
            <div className="sidebar-box">
              <div className="bio text-center">
                <img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García" className="img-fluid"/>
                <div className="bio-body">
                  <h2>Sandra García</h2>
                  <p>Socia Principal y Fundadora de Garlaw Abogados.<br/>
                      Licenciada en Derecho por la Universidad Complutense de Madrid.<br/>
                      Desde el año 2012 al año 2015 estuvo preparando la oposición al cuerpo de jueces y fiscales, consiguiendo superar dos de los tres ejercicios.<br/>
                      En el año 2016 decidió iniciar su actividad profesional como Abogada, ejerciendo la profesión hasta el día de hoy de forma ininterrumpida.
                  </p>
                  <p className="social">
                    <a title = "Garlaw Abogados Youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ" className="p-2"><i className="fa fa-youtube-play"></i></a>
                    <a title = "Garlaw Abogados Linkedin" href="https://www.linkedin.com/company/garlaw-abogados" className="p-2"><i className="fa fa-linkedin"></i></a>
                    <a title = "Garlaw Abogados Facebook" href="https://www.facebook.com/GarlawAbogados" className="p-2"><i className="fa fa-facebook"></i></a>
                    <a title = "Garlaw Abogados Twitter" href="https://twitter.com/garlawabogados" className="p-2"><i className="fa-brands fa-x-twitter"></i></a>
                  </p>
                </div>
              </div>
            </div>

            <div className="sidebar-box">
              <h3 className="heading">Destacados</h3>
              <div className="post-entry-sidebar">
                <ul>

                <li>
                  <NavLink exact to="/blog/post-actualizacion-renta-alquiler" title = "La actualización de la renta en los Contratos de Arrendamiento">
                      <img src="/assets/images/post_actualizacion_renta.webp" width="100%" height="100%" alt="La actualización de la renta en los Contratos de Arrendamiento" className="mr-4"/>
                      <div className="text">
                        <h4 className ="post-h4">La actualización de la renta en los Contratos de Arrendamiento</h4>
                        <div className="post-meta">
                          <span className="mr-3"><Icon icon="fa-regular:calendar-alt" height="12" hFlip={true}/></span>
                          <span className="mr-2">Enero 21, 2025</span>
                        </div>
                      </div>
                    </NavLink>
                  </li>   

                <li>
                  <NavLink exact to="/blog/post-ibi-pendiente-compraventa" title = "IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?">
                      <img src="/assets/images/post_ibi.webp" width="100%" height="100%" alt="IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?" className="mr-4"/>
                      <div className="text">
                        <h4 className ="post-h4">IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?</h4>
                        <div className="post-meta">
                          <span className="mr-3"><Icon icon="fa-regular:calendar-alt" height="12" hFlip={true}/></span>
                          <span className="mr-2">Diciembre 9, 2024</span>
                        </div>
                      </div>
                    </NavLink>
                  </li>   

                <li>
                    <NavLink exact to="/blog/post-sentencia-extranjera-de-divorcio" title = "Sentencia Extranjera de Divorcio, ¿es válida en España?">
                      <img src="/assets/images/post_sentencia_extranjera_divorcio.webp" width="100%" height="100%" alt="Sentencia Extranjera de Divorcio, ¿es válida en España?" className="mr-4"/>
                      <div className="text">
                        <h4 className ="post-h4">Sentencia Extranjera de Divorcio, ¿es válida en España?</h4>
                        <div className="post-meta">
                          <span className="mr-3"><Icon icon="fa-regular:calendar-alt" height="12" hFlip={true}/></span>
                          <span className="mr-2">Noviembre 11, 2024 </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>       
    
               
                </ul>
              </div>
            </div>
        </div>
        </div>
      </div>
    </section>
			</>
		)
 
	}
 
}
export default withRouter(Blog);